const theme = {
  colors: {
    text: "#111213",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#1A5F8E",
    secondary: "#111213",
    light: "#ffffff",
    dark: "#111213",
    lightGrey: "#F4F4F6",
  },
  fonts: {
    body: '"acumin-pro",system-ui, -apple-system, BlinkMacSystemFont, Arial,"Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"acumin-pro",system-ui, -apple-system, BlinkMacSystemFont, Arial,"Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 390, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    kicker: {
      textTransform: "uppercase",
      fontWeight: 300,
      fontSize: [4, 3, 3],
      pb: [3, 3],
    },

    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      letterSpacing: ["-2.875px", "-2.875px", "-4.875px"],
      fontSize: [6, 7],
      fontFamily: "acumin-pro-wide",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    cooperative: {
      fontSize: [6, 7],
      fontFamily: "acumin-pro-wide",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "acumin-pro-wide",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "heading",
      lineHeight: "heading",
      letterSpacing: "-0.45px",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  inputs: {
    dark: {
      p: [3],
      border: "unset",
      borderBottom: "1px solid",
      borderRadius: "0px!important",
      borderColor: "rgba(0,0,0,.3)",
      cursor: "pointer",
      color: "dark",
      "&:focus": {
        outline: "none",
        color: "dark",
        borderColor: "rgba(0,0,0,1)",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "dark",
        opacity: 1 /* Firefox */,
      },
    },
    light: {
      p: [3],
      border: "unset",
      borderBottom: "1px solid",
      borderRadius: "0px!important",
      borderColor: "rgba(255,255,255,.3)",
      cursor: "pointer",
      color: "light",
      "&:focus": {
        outline: "none",
        color: "light",
        borderColor: "rgba(255,255,255,1)",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "light",
        opacity: 1 /* Firefox */,
      },
    },
    primary: {
      borderColor: "light",
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "light",
      "&:focus": {
        outline: "none",
        backgroundColor: "light",
        color: "primary",
        border: "1px solid light",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "light",
        opacity: 1 /* Firefox */,
      },
    },
  },
  buttons: {
    primaryForm: {
      paddingY: 3,
      paddingX: 5,
      cursor: "pointer",
      color: "primary",
      bg: "light",
      borderRadius: "0px",
      border: "1px solid",
      borderColor: "transparent",
      "&:hover": {
        border: "1px solid",
        borderColor: "light",
        color: "light",
        bg: "primary",
        svg: {
          stroke: "light",
        },
      },
    },
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    light: {
      borderRadius: "0",
      backgroundColor: "light",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "primary",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "primary",
      textTransform: "uppercase",
      fontSize: [0],
      "svg *": {
        stroke: "primary",
      },
    },
    dark: {
      borderRadius: "0",
      backgroundColor: "transparent",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "primary",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "primary",
      textTransform: "uppercase",
      fontSize: [0],
      "svg *": {
        stroke: "primary",
      },
    },
    primary: {
      borderRadius: "0",
      backgroundColor: "transparent",
      textDecoration: "none",
      display: "inline-block",
      position: "relative",
      fontFamily: "heading",
      color: "primary",
      p: [3],
      px: [4],
      border: "1px solid",
      borderColor: "primary",
      textTransform: "uppercase",
      fontSize: [0],
      "svg *": {
        stroke: "primary",
      },
    },
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        textDecoration: "none",
        color: "light",
        "&:hover": {
          color: "light",
          textDecoration: "underline",
        },
      },
      "--swiper-theme-color": "#979797",

      ".swiper-container": { pb: 5 },
    },
  },
}

export default theme
